<template>
  <div class="home header-clear">
    <section class="section-home">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide
          v-for="slider in sliders"
          :key="slider.id"
        >
          <img :src="slider.img">
          <div class="slide-contain contain">
            <div class="slide-row">
              <div class="slide-col">
                <h4 data-aos="fade-right" data-aos-once="true" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-offset="300">{{ slider.subtitle }}</h4>
                <h2 data-aos="fade-right" data-aos-once="true" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-offset="300">{{ slider.title }}</h2>
                <v-btn
                  outlined
                  v-model="about" 
                  :id="about.title"
                  :to="about.to"
                  @click="about.to"
                  data-aos="fade-up" data-aos-once="true" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-offset="300"
                >
                  Научи повече
                </v-btn>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>
    <section class="section-about pl-9 pr-9">
      <v-row class="wrapper mt-12">
        <v-col class="wrapper mt-12"
          data-aos="fade-right" data-aos-once="true" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-offset="300"
        >
          <img class="infographic" src="infographic.svg" alt="about graphic">
        </v-col>
        <v-col class="wrapper-2 mt-12"
          data-aos="fade-up" data-aos-once="true" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-offset="300"
        >
          <h2 class="mb-4">Основните ни цели са по-качествени и достъпни комуникационни решения</h2>
          <p class="mb-6">
            "Point 2 Point" ЕООД понастоящем има изградено покритие в кварталите: <br> Обеля, Обеля 1, Обеля 2, Овча Купел, Горна Баня и Стрелбище. <br> Основна цел на фирмата е да предлага все по-качествени и достъпни комуникационни решения. Предимствата на оптичните и LAN мрежите в сравнение с другите средства за пренос на данни са
          </p>
        </v-col>
      </v-row>
      <v-row class="wrapper">
        <v-col
          v-for="feature in features"
          :key="feature.id"
          class="text-center mb-12 flex-100"
          max-width="150"
          width="100%"
        >
          <img :src="feature.img" height="75" width="75"/>
          <h4 class="mt-4">
            {{ feature.text }}
          </h4>
        </v-col>
      </v-row>
    </section>
    <section class="section-services pl-9 pr-9"
      data-aos="fade-up" data-aos-once="true" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-offset="300"
    >
      <div class="section-heading pt-12 ">
        <h2 class="pb-2 text-center d-block white--text">Услуги</h2>
        <p class="text-center pb-12 white--text">Изберете нашите атрактивни планове за интернет и телевизия на достъпни цени. <br> Откриите част от най-търсените планове в нашите райони.</p>
      </div>
      <v-row class="wrapper pb-12">
        <v-row class="justify-center mb-10 service-options" width="100">
          <v-btn class="mr-3 selected service-net service-button" @click="servicesClick">Интернет</v-btn>
          <v-btn class="mr-3 service-tv service-button" @click="servicesClick">Телевизия</v-btn>
          <v-btn class="service-combo service-button" @click="servicesClick">Комбиниран</v-btn>
        </v-row>
        <v-card
          class="mx-auto text-center mb-12 card card-services card-net is-visible"
          max-width="344"
          width="100%"
          color="#E66A1E"
          v-for="card in cards"
          :key="card.id"
        >
          <v-card-text>
            <h4 class="card-title mt-3">{{ card.title }}</h4>
            <p class="display-3 white--text mb-0 mt-4">
              {{ card.internet }}
            </p>
            <p class="white--text">{{ card.sub }}</p>
            <ul class="ul-text pa-0 mb-4 white--text">
              <li><v-icon
                dark
                right
                class="ml-0 mr-1"
              >
              mdi-check
            </v-icon>Ниски цени</li>
              <li><v-icon
                  dark
                  right
                  class="ml-0 mr-1"
                >
                mdi-check
                </v-icon>Висока скорост</li>
              <li><v-icon
                  dark
                  right
                  class="ml-0 mr-1"
                >
                mdi-check
              </v-icon>Надеждна връзка</li>
            </ul>
            <div class="white--text">
              <div>Цена</div>
              <span class="display-1 white--text">{{ card.price }}</span>
            </div>
          </v-card-text>
          <v-btn
              outlined
              color="#fff"
              class="text-center mb-6"
              v-model="about" 
              :id="about.title"
              :to="about.to"
              @click="about.to"
            >
              Научи повече
            </v-btn>
        </v-card>
        <v-card
          class="mx-auto text-center mb-12 card card-services card-tv"
          max-width="344"
          width="100%"
          color="#E66A1E"
          v-for="cardTv in cardsTv"
          :key="cardTv.id"
        >
          <v-card-text>
            <h4 class="card-title mt-3">{{ cardTv.title }}</h4>
            <div>
              <p class="display-3 white--text mb-0 mt-4">
                {{ cardTv.tv }}
              </p>
              <p class="white--text">{{ cardTv.sub }}</p>
            </div>
            <ul class="ul-text pa-0 mb-4 white--text">
              <li><v-icon
                dark
                right
                class="ml-0 mr-1"
              >
              mdi-check
            </v-icon>Ниски цени</li>
              <li><v-icon
                  dark
                  right
                  class="ml-0 mr-1"
                >
                mdi-check
                </v-icon>Богат избор</li>
              <li><v-icon
                  dark
                  right
                  class="ml-0 mr-1"
                >
                mdi-check
              </v-icon>Надеждна връзка</li>
            </ul>
            <div class="white--text">
              <div>Цена</div>
              <span class="display-1 white--text">{{ cardTv.price }}</span>
            </div>
          </v-card-text>
          <v-btn
              outlined
              color="#fff"
              class="text-center mb-6"
              v-model="about" 
              :id="about.title"
              :to="about.to"
              @click="about.to"
            >
              Научи повече
          </v-btn>
        </v-card>
         <v-card
          class="mx-auto text-center mb-12 card card-services card-combo"
          max-width="344"
          width="100%"
          color="#E66A1E"
          v-for="cardCombo in cardsCombo"
          :key="cardCombo.id"
        >
          <v-card-text>
            <h4 class="card-title mt-3">{{ cardCombo.title }}</h4>
            <div>
              <p class="display-3 white--text mb-0 mt-4">
                {{ cardCombo.tv }}
              </p>
              <p class="white--text">{{ cardCombo.sub }}</p>
            </div>
            <ul class="ul-text pa-0 mb-4 white--text">
              <li><v-icon
                dark
                right
                class="ml-0 mr-1"
              >
              mdi-check
            </v-icon>Ниски цени</li>
              <li><v-icon
                  dark
                  right
                  class="ml-0 mr-1"
                >
                mdi-check
                </v-icon>Висока скорост</li>
              <li><v-icon
                  dark
                  right
                  class="ml-0 mr-1"
                >
                mdi-check
              </v-icon>Надеждна връзка</li>
            </ul>
            <div class="white--text">
              <div>Цена</div>
              <span class="display-1 white--text">{{ cardCombo.price }}</span>
            </div>
          </v-card-text>
          <v-btn
              outlined
              color="#fff"
              class="text-center mb-6"
              v-model="about" 
              :id="about.title"
              :to="about.to"
              @click="about.to"
            >
              Научи повече
            </v-btn>
        </v-card>
      </v-row>
    </section>
    <section class="section-faq pl-9 pr-9"
      data-aos="fade-up" data-aos-once="true" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-offset="300"
    >
      <div class="section-heading pt-12">
        <h2 class="pb-2 text-center d-block">Често задавани въпроси</h2>
        <p class="text-center pb-12">P2P е на разположение за всякакви въпроси и запитвания относно нашите услуги. <br> Намерете ни на нашите офис локации или на телефоните за контакт.</p>
      </div>
      <v-row class="wrapper">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="faq in faqs"
            :key="faq.id"
          >
            <v-expansion-panel-header>
              {{ faq.question }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ faq.answer }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </section>
  </div>
</template>

<style lang="css">
  body {
    font-size: 1rem;
  }
  .header-clear {
    margin-top: 64px;
  }
  .section-home .col {
    max-width: 50%;
  }
  .wrapper-2 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-left: auto;
  }
  img {
    max-width: 100%;
  }
  h2 {
    font-size: 1.875rem;
    line-height: 1.4;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.4;
  }
  p {
    font-size: 1rem;
    line-height: 1.8;
  }
  .section-heading {
    max-width: 50%;
    margin: 0 auto;
  }
  .swiper-slide img {
    position: absolute;
    margin: auto;
    max-width: inherit;
  }
  .slide-contain::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
  }
  .contain {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 900px) {
    .wrapper-2 {
      flex: 0 0 100%;
      max-width: 100%;
      margin-left: auto;
    }
    p {
      font-size: 0.875rem;
    }
    img.infographic {
      max-width: 100%;
    }
    br {
      display: none;
    }
    section.section-faq {
      padding-bottom: 15%;
    }
  }
  @media only screen and (max-width: 600px) {
    .slide-row .slide-col h2 {
      font-size: 32px;
    }
    div.section-heading {
      max-width: 100%;
    }
    .flex-100 {
      flex: 1 1 100%;
    }
    section.section-faq {
      padding-bottom: 25%;
    }
  }
  @media only screen and (max-width: 450px) {
    .slide-row .slide-col h2 {
      max-width: 95%;
    }
    .v-data-table-header {
      display: none;
    }
  }
  @media only screen and (min-width: 768px) {
    .contain {
      max-width: 720px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .contain {
      max-width: 1200px;
    }
    .slide-col {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .slide-row {
    height: 630px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .slide-col {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .slide-col h4 {
    color: #fff;
    font-weight: normal;
    margin-bottom: 1rem;
  }
  .slide-col h2 {
    font-size: 3rem;
    line-height: 1.25;
    font-weight: 700;
    color: #fff;
    margin-bottom: 2rem;
  }
  .slide-row .slide-col a {
    color: #E66A1E;
  }
  .slide-row .slide-col a:hover {
    background-color: #E66A1E;
    color: #fff;
    border: 1px solid#E66A1E;
  }
  .swiper-pagination-bullet-active {
    background-color: #E66A1E;
    width: 10px;
    height: 10px;
  }
  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  .spacer {
    margin: 5% auto;
  }
  .spacer-2 {
    margin: 5% auto 2%;
  }
  .home h1 {
    color: #fff;
    padding-top: 5%;
    font-size: 60px;
  }
  .home h3 {
    color: #fff;
    font-size: 32px;
    max-width: 600px;
    margin: 40px auto;
  }
  .section-about h4 {
    padding-bottom: 20px;
  }
  .hero-button-wrapper {
    margin: 20px auto;
  }
  .section-about h3 {
    color: #000;
    text-align: left;
  }
  .section-services {
    background-color: #E66A1E;
  }
  .section-services span {
    color: #fff;
  }
  .service-options {
    width: 100%;
  }
  .service-options button {
    /* color: #E66A1E !important; */
    background-color: #E66A1E !important;
    border: 1px solid #fff;
  }
  .service-options button.selected {
    background-color: #fff !important;
  }
  .service-options button.selected span {
    color: #E66A1E !important;
  }
  span {
    color: #E66A1E;
  }
  .card {
    cursor: pointer;
    border-color: rgba(255,255,255,0.5) !important;
    border: 3px solid rgba(255,255,255,0.5);
  }
  .card:hover {
    border-color: #fff !important;
    border: 1px solid #fff;
  }
  .card-title {
    color: #fff;
  }
  .v-card.card-net,
  .v-card.card-tv,
  .v-card.card-combo {
    display: none;
  }
  .v-card.card-net.is-visible,
  .v-card.card-tv.is-visible,
  .v-card.card-combo.is-visible {
    display: block;
  }
  .ul-text {
    list-style-type: none;
    max-width: 150px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .coverage-image {
    max-width: 100%;
    margin: 0 auto;
  }
  .infographic {
    max-width: 75%;
  }
  section {
    padding-bottom: 2%;
  }
  .section-faq {
    padding-bottom: 7%;
  }
  @media only screen and (min-width: 1024px) {
    .coverage-image {
        max-width: 70%;
    }
  }
  @media only screen and (max-width: 450px) {
    .service-combo {
      margin-top: 12px;
    }
  }
</style>
  
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-pagination',
  title: 'Pagination',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    swiperOption: {
      pagination: {
        el: '.swiper-pagination'
      },
      autoplay: true,
      slidesPerView: 1,
      spaceBetween: 10,
      speed: 1500
    },
    sliders: [
      { id: '1', subtitle: 'Интернет връзка +', title: 'Изборът за стабилна интернет връзка', img: 'internet-img.jpg' },
      { id: '2', subtitle: 'IPTV телевизия +', title: 'Откриите един изцяло нов начин за гледане на телевизия', img: 'tv-img.jpg' }
    ],
    about: {
      title: 'Services',
      to: '/services'
    },
    features: [
      { id: '1', img: 'icon1.png', text: 'По-ниски цени и набор от пакети за вашите нужди' },
      { id: '2', img: 'icon2.png', text: 'Без допълнително закупуване на устройства' },
      { id: '3', img: 'icon3.png', text: 'Високо качество на обслужването и много други' }, 
    ],
    cards: [
      { id: '1', title: 'Стандарт - Нет', internet: '50', sub: 'Mbit/s', price: '20,00 лв' },
      { id: '2', title: 'Разширен - Нет', internet: '80', sub: 'Mbit/s', price: '30,00 лв' },
      { id: '3', title: 'Разширен Плюс - Нет', internet: '100', sub: 'Mbit/s', price: '40,00 лв' },
    ],
    cardsTv: [
      { id: '4', title: 'Стандарт - ТВ', tv: '35 +', sub: 'канала', subNet: 'Mbit/s', price: '12,00 лв' },
      { id: '5', title: 'Разширен - ТВ', tv: '100 +', sub: 'канала', subNet: 'Mbit/s', price: '15,00 лв' },
      { id: '6', title: 'Разширен Плюс - ТВ', tv: '190 +', sub: 'канала', subNet: 'Mbit/s', price: '18,00 лв' },
    ],
    cardsCombo: [
      { id: '7', title: 'Стандарт - Комбиниран', net: '50', tv: 'НЕТ & ТВ', sub: 'канала', subNet: 'Mbit/s', price: '22,00 лв' },
      { id: '8', title: 'Разширен - Комбиниран', net: '80', tv: 'НЕТ & ТВ', sub: 'канала', subNet: 'Mbit/s', price: '27,00 лв' },
      { id: '9', title: 'Разширен Плюс - Комбиниран', net: '100', tv: 'НЕТ & ТВ', sub: 'канала', subNet: 'Mbit/s', price: '32,00 лв' },
    ],
    faqs: [
      { id: '1', question: 'Какво е необходимото оборудване за включване към вашата мрежа ?', answer: '* За да имате интернет достъп е необходимо да имате мрежова (LAN) карта на вашия компютър. Aко не разполагате с такава, мрежовата карта може да бъде осигурена и от нас. Цена – 10лв.' },
      { id: '2', question: 'Ще разполагам ли с IP адрес ?', answer: '* Да. Получавате реално IP.' },
      { id: '3', question: 'Възможно ли е да се включи повече от 1 компютър към Интернет ?', answer: '* P2P не ограничава броя на използваните компютри и НЕ изисква от клиентите си да доплащат допълнителни такси.' },
      { id: '4', question: 'Имам ли ограничение на броя сесии?', answer: '* Не няма.' }, 
      { id: '5', question: 'Ще ползвам ли отстъпка, ако предплатя за по-продължителен период ?', answer: '* Да. Свържете се с нас за повече информация.' }, 
    ],
  }),
  methods: {
    servicesClick: e => {
      const service = e.target.textContent;

      let buttonNet = document.querySelector('.service-net');
      let buttonTv = document.querySelector('.service-tv');
      let buttonCombo = document.querySelector('.service-combo');
      let cardNet = document.querySelectorAll('.card-net');
      let cardTv = document.querySelectorAll('.card-tv');
      let cardCombo = document.querySelectorAll('.card-combo');
      let card = document.querySelectorAll('.card-services');
      let button = document.querySelectorAll('.service-button');

      if(service === 'Интернет') {
        card.forEach(function(card) {
          card.classList.remove('is-visible');
        });
        button.forEach(function(button) {
          button.classList.remove('selected');
        });

        buttonNet.classList.add('selected');
        cardNet.forEach(function(card) {
          card.classList.add('is-visible');
        });
      } else if (service === 'Телевизия') {
        card.forEach(function(card) {
          card.classList.remove('is-visible');
        });
        button.forEach(function(button) {
          button.classList.remove('selected');
        });
        
        buttonTv.classList.add('selected');
        cardTv.forEach(function(card) {
          card.classList.add('is-visible');
        });
      } else {
        card.forEach(function(card) {
          card.classList.remove('is-visible');
        });
        button.forEach(function(button) {
          button.classList.remove('selected');
        });

        buttonCombo.classList.add('selected');
        cardCombo.forEach(function(card) {
          card.classList.add('is-visible');
        });
      }
    },
  }
}
</script>

