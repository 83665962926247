<template>
  <v-app>
    <v-app-bar
      elevate-on-scroll
      color="white"
    >
      <div class="d-flex wrapper align-center header-wrapper">
        <router-link
          to="/"
          class="pt-1"
        >
          <img src="/logo.gif" alt="p2p logo" />
        </router-link>

        <v-spacer></v-spacer>

        <div class="desktop-header">
          <v-menu
            open-on-hover
            top
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- <a
                href="/services"
                class="pa-4 pb-5"
                v-bind="attrs"
                v-on="on"
              >
                Услуги
              </a> -->
              <router-link
                to="/services"
                v-slot="{ href, navigate }"
              >
                <a
                  :href="href"
                  class="pa-4 pb-5"
                  v-bind="attrs"
                  v-on="on"
                  @click="navigate"
                >
                  Услуги
                </a>
              </router-link>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in dropItems"
                :key="index"
              >
                <router-link :to="item.link">{{ item.title }}</router-link>
              </v-list-item>
            </v-list>
          </v-menu>

          <router-link
            to="/legal"
            class="pa-4 pb-5"
          >
            Общи Условия
          </router-link>

          <router-link
            to="/contacts"
            class="pa-4 pb-5"
          >
            Контакти
          </router-link>
        </div>
        <div class="mobile-header">
          <v-app-bar-nav-icon
            @click="drawer = true"
          >
          </v-app-bar-nav-icon>
        </div>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
      >
        <v-list-item-group>
          <v-list-item>
            <router-link
            to="/"
            class="pt-2 pb-2"
            >
              <img
                src="logo.gif"
                alt="p2p logo"
              >
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link
            to="/services"
            class="pt-2 pb-2"
            >
              Услуги
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              to="/legal"
              class="pt-2 pb-2"
            >
              Общи Условия
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              to="/contacts"
              class="pt-2 pb-2"
            >
              Контакти
            </router-link>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer
    color="#E66A1E"
    >
      <v-row
        class="wrapper-footer mt-8 mb-4 ml-auto mr-auto justify-center"
      >
        <v-col class="flex-column d-flex">
          <h2 class="white--text">Point 2 Point</h2>
          <ul class="white--text pl-0 mt-2">
            <li><v-icon
              dark
              right
              class="ml-0 mr-2"
            >
              mdi-map-marker
            </v-icon>Адрес: гр. София</li>
            <li class="ml-8">
              ж.к. Обеля 2 бл. 252 вх. Б
            </li>
          </ul>
          <p class="white--text mt-2"><v-icon
              dark
              right
              class="ml-0 mr-2"
            >
              mdi-email
            </v-icon>E-mail: <a href="mailto: office@p2p-bg.net" class="white--text">office@p2p-bg.net</a></p>
        </v-col>
        <v-col>
          <p class="white--text mt-2">
            <v-icon
              dark
              right
              class="ml-0 mr-0"
            >
              mdi-phone
            </v-icon>
            Тел: 02/ 934 24 27</p>
          <ul v-for="telephone in telephones" :key="telephone.id" class="white--text pl-0">
            <li>{{ telephone.tel }}</li>
          </ul>
        </v-col>
        <v-col class="legal">
          <p class="white--text mt-2 mb-2">
            <v-icon
              dark
              right
              class="ml-0 mr-0"
            >
              mdi-clock
            </v-icon>
            Работно време
          </p>
          <ul class="white--text pl-0 mb-6">
            <li>Пон - Пет: 10:00 - 20:00</li>
            <li>Съб - Нед: 12:00 - 19:00</li>
          </ul>
        </v-col>
      </v-row>
      <v-row
        class="wrapper-footer mt-0 mb-0 ml-auto mr-auto justify-center"
      >
          <v-btn
            v-for="item in items"
            :key="item.id"
            :to="item.to"
            color="white"
            text
            rounded
            class="my-2"
            link
          >
            {{ item.title }}
        </v-btn>
      </v-row>
      <v-row class="wrapper-footer mt-0 mb-0 ml-auto mr-auto justify-center mt-4 border-top">
        <p class='white--text'>© {{ new Date().getFullYear() }} Point 2 Point. Всички права запазени.</p>
      </v-row>
    </v-footer>
  </v-app>
</template>

<style lang="scss">
  header.v-app-bar {
    position: fixed;
    z-index: 2;
  }
  .header-wrapper {
    width: 100%;
    justify-content: space-between;
  }
  .header-wrapper .desktop-header a {
    color: #000;
    text-decoration: none;
  }
  .header-wrapper .desktop-header a:hover {
    opacity: 0.8;
    border-bottom: 3px solid #E66A1E;
  }
  aside.v-navigation-drawer--temporary {
    position: fixed;
  }
  .v-navigation-drawer__content .v-list a {
    color: #000;
    text-decoration: none;
  }
  .v-navigation-drawer__content .v-list a:hover {
    color: #E66A1E;
  }
  .wrapper-footer {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .white {
    color: #fff;
  }
  .border-wrapper {
    margin-top: 15px;
  }
  .border-top {
    padding-top: 15px;
    border-top: 1px solid #fff;
  }
  footer ul {
    list-style-type: none;
  }
  .legal.col a {
    text-decoration: none;
    color: #fff;
  }
  .legal.col a:hover {
    text-decoration: underline;
  }
  .mobile-header {
    display: none;
  }
  .work-hours-2 {
    display: none;
  }
  .v-menu__content {
    top: 45px !important;
    a {
      color: #000 !important;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .mobile-header {
      display: block;
    }
    .desktop-header {
      display: none;
    }
    footer .wrapper-footer {
      display: block;
    }
    footer .wrapper-footer div:not(:last-of-type) {
      display: inline-block !important;
      width: 50%;
    }
    .work-hours {
      display: none;
    }
    .work-hours-2 {
      display: block;
    }
  }
  @media only screen and (max-width: 450px) {
    footer .wrapper-footer div:not(:last-of-type) {
      display: block;
      width: 100%;
    }
    .work-hours {
      display: block;
    }
    .work-hours-2 {
      display: none;
    }
  }
</style>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null, 
    home: {
      title: 'Home',
      to: '/'
    },
    items: [
      { id: '1', title: 'Home', to:'/' },
      { id: '2', title: 'Услуги', to:'/services' },
      { id: '3', title: 'Общи Условия', to:'/legal'},
      { id: '4', title: 'Контакти', to:'/contacts'},
    ],
    telephones: [
      { id: '1', tel: '0897 80 52 19 - 0888 72 40 19'},
      { id: '2', tel: '0888 25 60 96 - 0878 02 25 69'},
    ],
    dropItems: [
      { title: 'Интернет', link: '/services-net' },
      { title: 'Телевизия', link: '/services-tv' },
      { title: 'Комбиниран', link: '/services-combo'},
    ],
  }),
  methods: {
    checkDate: () => {
      const date = new Date.now();
      console.log(date)
    }
  }
}
</script>
