import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/services',
    name: 'Услуги',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contacts',
    name: 'Контакти',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue')
  },
  {
    path: '/legal',
    name: 'Общи Условия',
    component: () => import(/* webpackChunkName: "legal" */ '../views/Legal.vue')
  },
  {
    path: '/services-net',
    name: 'Интернет',
    component: () => import(/* webpackChunkName: "net" */ '../views/Net.vue')
  },
  {
    path: '/services-tv',
    name: 'Телевизия',
    component: () => import(/* webpackChunkName: "tv" */ '../views/Tv.vue')
  },
  {
    path: '/services-tv-channels',
    name: 'Канали',
    component: () => import(/* webpackChunkName: "tv" */ '../views/Channels.vue')
  },
  {
    path: '/services-combo',
    name: 'Комбиниран',
    component: () => import(/* webpackChunkName: "combo" */ '../views/Combo.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
